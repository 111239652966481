import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
import { Model } from "../components/GuppyObj-keyframes";
import { extend } from '@react-three/fiber'
import LoaderDealio from "./LoaderDealio";

//import CubeMapFiles from 'cubeMap'
//import HDRPath from './cubeMap/'

function GuppyComponentViewer(props) {
  if(props.dialerChoice == ""){
    return;
  }
  let modName ="GuppyObj-2";
    return (
      <Canvas
        camera={{
          position: [-10, 2, 15],
          fov: 30,
          zoom: 0.5,
          near: 0.1,
          far: 500,
        }}
        shadows={true}
      >
        <ambientLight intensity={1} />
        <spotLight
          intensity={3}
          position={[-30, 30, 10]}
          angle={0.25}
          penumbra={1}
          decay={25}
          castShadow={true}
          receiveShadow={true}
        />
        <pointLight
          intensity={1}
          position={[0, 20, 5]}
          penumbra={1}
          decay={15}
          castShadow={true}
          receiveShadow={true}
        />

        <Suspense fallback={<LoaderDealio
            dialerCallback={props.dialerCallback}
          />
        }>
          {/* <RandomBG /> */}
          <Environment
            files={["px.jpg", "nx.jpg", "py.jpg", "ny.jpg", "pz.jpg", "nz.jpg"]}
            path="/"
            background={true}
            resolution={2048}
            near={1}
            far={1000}
            castShadow={true}
            receiveShadow={true}
          />
          <Model castShadow={true} receiveShadow={true} modelName={modName} />
          <OrbitControls
            rotation={[0, 0, 15]}
            enableZoom={false}
            enableDamping={true}
            dampingFactor={0.05}
            autoRotate={true}
            autoRotateSpeed={0.3}
          />
        </Suspense>
      </Canvas>
    );
}

export default GuppyComponentViewer;
