/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect, play } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Model(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/GuppyObj-keyframes.gltf')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions['Action.014']);
    actions['Action.014'].play();
  });
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
          <mesh name="belly001" geometry={nodes.belly001.geometry} material={materials['Material.001']} />
        <mesh name="body001" geometry={nodes.body001.geometry} material={materials['Material.002']} />
        <mesh name="body002" geometry={nodes.body002.geometry} material={materials['Material.002']} />
        <mesh name="body003" geometry={nodes.body003.geometry} material={materials['Material.002']} />
        <mesh name="body004" geometry={nodes.body004.geometry} material={materials['Material.002']} />
        <mesh name="body005" geometry={nodes.body005.geometry} material={materials['Material.002']} />
        <mesh name="body006" geometry={nodes.body006.geometry} material={materials['Material.002']} />
        <mesh name="body007" geometry={nodes.body007.geometry} material={materials['Material.002']} />
        <mesh name="cheek-main" geometry={nodes['cheek-main'].geometry} material={materials['Material.002']} />
        <mesh name="cheek002" geometry={nodes.cheek002.geometry} material={materials['Material.002']} />
        <mesh name="eye-bottom" geometry={nodes['eye-bottom'].geometry} material={materials['Material.002']} />
        <mesh name="eye-bottom002" geometry={nodes['eye-bottom002'].geometry} material={materials['Material.002']} />
        <group name="eyeL" position={[1.33, 1.78, 5.44]} rotation={[0, -0.06, 0]}>
          <mesh name="Sphere001" geometry={nodes.Sphere001.geometry} material={materials['iris-colors']} />
          <mesh name="Sphere001_1" geometry={nodes.Sphere001_1.geometry} material={materials['3D-Export.001']} />
          <mesh name="Sphere001_2" geometry={nodes.Sphere001_2.geometry} material={materials['iris-light.001']} />
        </group>
        <group name="eyeR" position={[-1.33, 1.83, 5.44]} rotation={[-Math.PI, -0.06, 0]} scale={-1}>
          <mesh name="Sphere010" geometry={nodes.Sphere010.geometry} material={materials['iris-colors']} />
          <mesh name="Sphere010_1" geometry={nodes.Sphere010_1.geometry} material={materials['3D-Export.001']} />
          <mesh name="Sphere010_2" geometry={nodes.Sphere010_2.geometry} material={materials['iris-light.001']} />
        </group>
        <mesh name="eyebrow" geometry={nodes.eyebrow.geometry} material={materials['Material.002']} />
        <mesh name="eyebrow002" geometry={nodes.eyebrow002.geometry} material={materials['Material.002']} />
        <mesh name="Gill001" geometry={nodes.Gill001.geometry} material={materials['Material.002']} />
        <mesh name="Gill002" geometry={nodes.Gill002.geometry} material={materials['Material.002']} />
        <mesh name="lower-lip" geometry={nodes['lower-lip'].geometry} material={materials['Material.002']} />
        <mesh name="lower-lip002" geometry={nodes['lower-lip002'].geometry} material={materials['Material.002']} />
        <mesh name="nose-main" geometry={nodes['nose-main'].geometry} material={materials['Material.002']} />
        <mesh name="nostril" geometry={nodes.nostril.geometry} material={materials['Material.002']} />
        <mesh name="side-fin-left" geometry={nodes['side-fin-left'].geometry} material={materials['Material.002']} />
        <mesh name="side-finright" geometry={nodes['side-finright'].geometry} material={materials['Material.002']} />
        <mesh name="tail" geometry={nodes.tail.geometry} material={materials['Material.002']} />
        <mesh name="top-fin" geometry={nodes['top-fin'].geometry} material={materials['Material.002']} />
        <mesh name="top-fin001" geometry={nodes['top-fin001'].geometry} material={materials['Material.002']} />
        <mesh name="upper-lip" geometry={nodes['upper-lip'].geometry} material={materials['Material.002']} />
        <mesh name="upper-lip002" geometry={nodes['upper-lip002'].geometry} material={materials['Material.002']} />
      </group>
    </group>
  )
}

useGLTF.preload('/GuppyObj-keyframes.gltf')
