import React from "react";
import "../fonts/sofachrome.rg-regular.ttf";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";

import InputAdornment from "@mui/material/InputAdornment";
import Dialog from "@mui/material/Dialog";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import {

  Email,
  AccountCircle,

} from "@mui/icons-material";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const McForm = styled.div`
  //background: red;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items:center;
  align-content:center;

  @media (min-width: 320px) {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
  @media (min-width: 768px) {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
  @media (min-width: 1024px) {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
  @media (min-width: 1800px) {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
  @media (min-width: 2560px) {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
`;

// const SubscribedMessage = styled.div`
// padding: 5px 0px 0px 0px;
// margin: 0px;
// font-family: 'ethnocentric-light';
// letter-spacing: 2ch;
// line-height: 1.5;
// color: black;
// //background: yellow;

// @media (min-width: 320px) {
//   font-size: 1.8ch;
//   display: none;
// }
// @media (min-width: 768px) {
//   font-size: 1.4ch;
//   display: block;
// }
// @media (min-width: 1024px) {
//   font-size: 1.4ch;
//   display: block;
// }
// @media (min-width: 1800px) {
//     font-size: 1.6ch;
//     display: block;
// }
// @media (min-width: 2560px) {
//   font-size: 2ch;
//   display: block;
// }
// `

const CustomForm = () => {
  const [open, setOpen] = React.useState(false);
  const [firstError, setFirstError] = React.useState(null);
  const [lastError, setLastError] = React.useState(null);
  const [emailError, setEmailError] = React.useState(null);
  const [submitted, setSubmitted] = React.useState(false);
  const [firstName, setFirstName] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const [email, setEmail] = React.useState(null);

  const handleOpen = () => {
    setOpen(true);
    setSubmitted(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

  const handleSubmit = (e) => {
    // Prevent the default onSubmit behavior
    e.preventDefault();
    // POST the encoded form with the content-type header that's required for a text submission
    // Note that the header will be different for POSTing a file
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ 
        "form-name": e.target.getAttribute("name"),
        ...firstName,
        ...lastName,
        ...email
      })
    })
      // On success, redirect to the custom success page using Gatsby's `navigate` helper function
      .then(() => {
        setSubmitted(true);
        setOpen(false);
      })
      // On error, show the error in an alert
      .catch(error => alert(error));
  };

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        {/* <McFormMain>  */}
        <McForm>
          
            <Button
              onClick={handleOpen}
              variant="contained"
              size="large"
              // endIcon={<EmojiEmotions />}
              sx={{
                width: 255,
                color: "#ffffff",
                backgroundColor: "#b94bff",
                "&:hover": {
                  backgroundColor: "#000000",
                  color: "#ffffff",
                },
                margin: 0,
                fontFamily: "ethnocentric",
                fontSize: 12,
                fontWeight: 0,
                letterSpacing: 5,
                paddingBottom: 1.5,
                marginBottom: 1,
              }}
            >
              {submitted? "SUBMISSION RECEIVED" : "REQUEST INVITE"}
            </Button>

            {/*Experimented with a seperate success display, couldn't get it to look right*/}
{/*            <SubscribedMessage>SUBMISSION{"\n"}RECEIVED</ SubscribedMessage>
            {submitted && <SubscribedMessage>SUBMISSION{"\n"}RECEIVED</ SubscribedMessage>}*/}

            <Dialog
              open={open}
              onClose={handleClose}
              sx={{
                color: "#b94bff",
              }}
            >
            <form name="Request-Invite" action="/" onSubmit={handleSubmit} method="post" style={{'display':'contents'}}>
              <input type="hidden" name="form-name" value="Request-Invite" />
              <TextField
                label="FIRST NAME"
                name="firstName"
                size="small"
                variant="outlined"
                onChange={(e)=>{
                  setFirstError(e.target.value === "" ? "FIRST NAME REQUIRED" : "");
                  setFirstName({ ...firstName, [e.target.name]: e.target.value })
                }}
                error={firstError !== null && firstError !==""}
                helperText={(firstError !== null && firstError !=="") ? firstError : ""}
                FormHelperTextProps={{
                  sx:{
                    fontFamily: "ethnocentric",
                    fontSize: 11,
                    letterSpacing: 3,
                  }
                }}
                sx={{
                  width: 255,
                  marginTop: 1,
                  marginLeft: 1,
                  "& .MuiFormLabel-root": {
                    fontFamily: "ethnocentric",
                    fontSize: 11,
                    letterSpacing: 3,
                  },
                }}
                //helperText="Please enter your first name"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                centered
              />

              <TextField
                label="LAST NAME"
                name="lastName"
                size="small"
                variant="outlined"
                onChange={(e)=>{
                  setLastError(e.target.value === "" ? "LAST NAME REQUIRED" : "");
                  setLastName({ ...lastName, [e.target.name]: e.target.value })
                }}
                error={lastError !== null && lastError !==""}
                helperText={(lastError !== null && lastError !=="") ? lastError : ""}
                FormHelperTextProps={{
                  sx:{
                    fontFamily: "ethnocentric",
                    fontSize: 11,
                    letterSpacing: 3,
                  }
                }}
                sx={{
                  width: 255,
                  marginTop: 1,
                  marginLeft: 1,
                  "& .MuiFormLabel-root": {
                    fontFamily: "ethnocentric",
                    fontSize: 11,
                    letterSpacing: 3,
                  },
                }}
                //helperText="Please enter your last name"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                centered
              />

              <TextField
                label="EMAIL"
                name="email"
                size="small"
                type="email"
                variant="outlined"
                FormHelperTextProps={{
                  sx:{
                    fontFamily: "ethnocentric",
                    fontSize: 11,
                    letterSpacing: 3,
                  }
                }}
                sx={{
                  width: 255,
                  marginTop: 1,
                  marginLeft: 1,
                  "& .MuiFormLabel-root": {
                    fontFamily: "ethnocentric",
                    fontSize: 11,
                    letterSpacing: 3,
                  },
                }}
                onChange={(e)=>{
                  if(e.target.value === ""){
                    setEmailError("EMAIL REQUIRED");
                  }
                  else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
                    setEmailError("INVALID EMAIL");
                  }
                  else{
                    setEmailError("");
                  }
                  setEmail({ ...email, [e.target.name]: e.target.value })
                }}
                error={emailError !== null && emailError !==""}
                helperText={(emailError !== null && emailError !=="") ? emailError : ""}
                // helperText="Enter a valid email address"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Email />
                    </InputAdornment>
                  ),
                }}
                centered
              />

              <Button
                variant="contained"
                size="large"
                type="submit"
                disabled={(emailError == "" && firstError == "" && lastError === "")  ? false : true}
                // endIcon={<EmojiEmotions />}
                sx={{
                  width: 255,
                  color: "#ffffff",
                  backgroundColor: "#b94bff",
                  "&:hover": {
                    backgroundColor: "#000000",
                    color: "#ffffff",
                  },
                  margin: 1,
                  fontFamily: "ethnocentric",
                  fontSize: 12,
                  fontWeight: 0,
                  letterSpacing: 5,
                  paddingBottom: 1.5,
                }}
                centered
              >
                Submit
              </Button>
              </form>
            </Dialog>
          
        </McForm>
        {/* </McFormMain> */}
      </ThemeProvider>
    </>
  );
};

class Subscriber extends React.Component {
  render() {
    return <CustomForm />;
  }
}
export default Subscriber;
