import React from "react";
import SpeedDial from "@mui/material/SpeedDial";
import AllOutIcon from "@mui/icons-material/AllOut";
import SpeedDialAction from "@mui/material/SpeedDialAction";

import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import BoltIcon from "@mui/icons-material/Bolt";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import ClearIcon from '@mui/icons-material/Clear';

const SpeedDialer = (props) => {
  const actions = [
    { icon: <RocketLaunchIcon />, name: "Cosmic" },
    { icon: <BoltIcon />, name: "Voltaic" },
    { icon: <GraphicEqIcon />, name: "Sonic" },
  ];
  return (
    <>
      <SpeedDial
        ariaLabel="To The Moon"
        sx={{
          position: "absolute",
          bottom: 60,
          right: "5vw",
          "& .MuiSpeedDialIcon-root": {
            color: "white",
            backgroundColor: "#b94bff",
            "&:hover": {
              backgroundColor: "#000000",
              color: "#ffffff",
            },
            fill: "#00c0ff",
          },
          "& .MuiFab-primary": {
            color: "white",
            backgroundColor: "#b94bff",
            "&:hover": {
              backgroundColor: "#000000",
              color: "#ffffff",
            },
            fill: "#b94bff",
          },
        }}
        icon={<AllOutIcon fontSize="large" />}
      >
      {(props.dialerChoice !=='' && props.dialerChoice !==null) &&(
          <SpeedDialAction
            key={"Back to Static"}
            icon={<ClearIcon />}
            tooltipTitle={"Back to Static"}
            onClick={()=>{
              props.dialerCallback("");
              props.loadingCallback(true);
            }}
          />
        )
      }
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={()=>{
              props.dialerCallback(action.name);
              props.loadingCallback(true);
            }}
          />
        ))}
      </SpeedDial>
    </>
  );
};

function SpeedDialComponent(props){
  return (
    <>
      <SpeedDialer
        dialerChoice={props.dialerChoice}
        dialerCallback={props.dialerCallback}
        loadingCallback={props.loadingCallback}
      />
    </>
  );
}
export default SpeedDialComponent;
