import React from 'react'
import { Html, useProgress } from '@react-three/drei'
import '../fonts/sofachrome.rg-regular.ttf'
import styled from 'styled-components'
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';
import IconButton from '@mui/material/IconButton';
import { extend } from '@react-three/fiber'
extend({ IconButton, CancelPresentationOutlinedIcon, CircularProgress, Button })

const LoaderContainer = styled.div`
position: absolute;
min-width: 100vw;
min-height: 100vh;
padding: 0px;
margin: 0px;
`
const LoaderContent = styled.div`
position: absolute;
min-width: 400px;
min-height: 200px;
padding: 0px;
margin: 0px;
top: 50vh;
vertical-align: top;
text-align: left;
font-family: 'ethnocentric-ultra-light';
font-size: 10ch;
`

const LoadBot = () => {
  const { active, progress, errors, item, loaded, total } = useProgress();
  return <Html center>Loading: {parseInt(progress)}%</Html>
}


function LoaderDealio(props) {

  return (
      <LoadBot />
  )
}
export default LoaderDealio;
