import React from "react";
import AnimationJson from "./assets/animation.json";
import styled from "styled-components";
import Lottie from 'react-lottie-player'


// Canvas for 3d objects
const CanvasContainer = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  min-height: 800px;
  margin: 0;
  padding: 0;
  background: white;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

function LoaderComponent(props){
    return (
      <>
        <CanvasContainer>
          <Lottie
            loop={1}
            animationData={AnimationJson}
            onComplete={()=>{
              props.loadingCallback(false);
            }}
            play 
            style={{ width: '90%', height: '90%' }}
          />
        </CanvasContainer>
      </>
    );
}
export default LoaderComponent;
