import React from 'react'
import GuppyImage1 from '../media/white-black-eyes.png'
import GuppyImage2 from '../media/white-transparent-rough.png'
import GuppyImage3 from "./assets/experiment-01.jpg";
import styled from 'styled-components'




const GuppyImageComponent = styled.img`
position: absolute;
top: 50vh;
left: 50vw;
transform: translate(-50%, -50%);

@media (min-width: 320px) {
  width: 80vw;
}
@media (min-width: 768px) {
  width: 75vw;
}
@media (min-width: 1024px) {
  width: 45vw;
}
@media (min-width: 1800px) {
  width: 40vw;
}
@media (min-width: 2560px) {
  width: 35vw;
}
`

const RANDOM_GUP = false;
const gupImages = [GuppyImage1, GuppyImage2]
let gupImage = gupImages[0];

function StaticGuppy(props) {
  
  // switch(props.guppyChoice){
  //   case "Voltaic":
  //     gupImage=gupImages[0];
  //     break;
  //   case "Cosmic":
  //     gupImage=gupImages[1];
  //     break;
  //   case "Sonic":
  //     gupImage=gupImages[1];
  //     break;
  //   default:
  //     if(RANDOM_GUP){
  //       gupImage = gupImages[Math.floor(Math.random() * gupImages.length)]
  //     }
  // }

      return (
            <GuppyImageComponent src={gupImage} alt="NoobGuppy"></ GuppyImageComponent>
      )
  }
  export default StaticGuppy;