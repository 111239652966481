const litePaperData = 
[
  {
    titleNo: "01",
    titleName: "Abstract",
    body: "",
    subCategories: [
      {
        subTitle: "Web 3",
        body: "This is the Web 3 body text",
      },
      {
        subTitle: "DAO",
        body: "This is the DAO body text",
      },
      {
        subTitle: "Venture Capital",
        body: "This is the Venture Capital body text",
      },
    ],
  },

  {
    titleNo: "02",
    titleName: "Introduction",
    body: "",
    subCategories: [
      {
        subTitle: "Web 3",
        body: "This is the Web 3 body text",
      },
      {
        subTitle: "DAO",
        body: "This is the DAO body text",
      },
      {
        subTitle: "Venture Capital",
        body: "This is the Venture Capital body text",
      },
    ],
  },

  {
    titleNo: "03",
    titleName: "Value Proposition",
    body: "",
    subCategories: [
      {
        subTitle: "Web 3",
        body: "This is the Web 3 body text",
      },
      {
        subTitle: "DAO",
        body: "This is the DAO body text",
      },
      {
        subTitle: "Venture Capital",
        body: "This is the Venture Capital body text",
      },
    ],
  },






  {
    titleNo: "04",
    titleName: "Fund 1",
    body: "",
    subCategories: [
      {
        subTitle: "Web 3",
        body: "This is the Web 3 body text",
      },
      {
        subTitle: "DAO",
        body: "This is the DAO body text",
      },
      {
        subTitle: "Venture Capital",
        body: "This is the Venture Capital body text",
      },
    ],
  },

















 










  {
    titleNo: "05",
    titleName: "NFT Series 1",
    body: "",
    subCategories: [
      {
        subTitle: "Web 3",
        body: "This is the Web 3 body text",
      },
      {
        subTitle: "DAO",
        body: "This is the DAO body text",
      },
      {
        subTitle: "Venture Capital",
        body: "This is the Venture Capital body text",
      },
    ],
  },


















  {
    titleNo: "06",
    titleName: "Governance",
    body: "",
    subCategories: [
      {
        subTitle: "Web 3",
        body: "This is the Web 3 body text",
      },
      {
        subTitle: "DAO",
        body: "This is the DAO body text",
      },
      {
        subTitle: "Venture Capital",
        body: "This is the Venture Capital body text",
      },
    ],
  },



































  {
    titleNo: "07",
    titleName: "Scouting & Due Diligence",
    body: "",
    subCategories: [
      {
        subTitle: "Web 3",
        body: "This is the Web 3 body text",
      },
      {
        subTitle: "DAO",
        body: "This is the DAO body text",
      },
      {
        subTitle: "Venture Capital",
        body: "This is the Venture Capital body text",
      },
    ],
  },

























  {
    titleNo: "08",
    titleName: "Investment Committee",
    body: "",
    subCategories: [
      {
        subTitle: "Web 3",
        body: "This is the Web 3 body text",
      },
      {
        subTitle: "DAO",
        body: "This is the DAO body text",
      },
      {
        subTitle: "Venture Capital",
        body: "This is the Venture Capital body text",
      },
    ],
  },


























  {
    titleNo: "09",
    titleName: "Gilded Guppies",
    body: "",
    subCategories: [
      {
        subTitle: "Web 3",
        body: "This is the Web 3 body text",
      },
      {
        subTitle: "DAO",
        body: "This is the DAO body text",
      },
      {
        subTitle: "Venture Capital",
        body: "This is the Venture Capital body text",
      },
    ],
  },



























  {
    titleNo: "10",
    titleName: "The Elephant In The Room",
    body: "",
    subCategories: [
      {
        subTitle: "Web 3",
        body: "This is the Web 3 body text",
      },
      {
        subTitle: "DAO",
        body: "This is the DAO body text",
      },
      {
        subTitle: "Venture Capital",
        body: "This is the Venture Capital body text",
      },
    ],
  },



























  {
    titleNo: "11",
    titleName: "The NGD Team",
    body: "",
    subCategories: [
      {
        subTitle: "Web 3",
        body: "This is the Web 3 body text",
      },
      {
        subTitle: "DAO",
        body: "This is the DAO body text",
      },
      {
        subTitle: "Venture Capital",
        body: "This is the Venture Capital body text",
      },
    ],
  },






  {
    titleNo: "12",
    titleName: "Appendix/Citations",
    body: "",
    subCategories: [
      {
        subTitle: "Web 3",
        body: "This is the Web 3 body text",
      },
      {
        subTitle: "DAO",
        body: "This is the DAO body text",
      },
      {
        subTitle: "Venture Capital",
        body: "This is the Venture Capital body text",
      },
    ],
  },






]

export default litePaperData