import React, { Suspense, useState } from "react";
import LoaderDealio from "./components/LoaderDealio";
import NavAndPagesComponent from "./components/NavAndPagesComponent";
import CanvasComponent from "./components/CanvasComponent";
import LoaderComponent from "./components/LoaderComponent";
import HeaderComponent from "./components/HeaderComponent";
import SpeedDialComponent from "./components/SpeedDialComponent";

function App(){
  const [dialerChoice, setDialerChoice] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <Suspense fallback={<LoaderDealio />}>
        <CanvasComponent 
          dialerChoice={dialerChoice}
          dialerCallback={setDialerChoice}
        />
        <HeaderComponent />
        <NavAndPagesComponent />
        <SpeedDialComponent
          dialerCallback={setDialerChoice}
          loadingCallback={setIsLoading}
          dialerChoice={dialerChoice}
        />
      </Suspense>
    </>
  );
}
export default App;
