import React from "react"
import styled from "styled-components"
import Button from '@mui/material/Button';
import { Dialog, Stack, Link, Alert } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LitePaperComponent from "./LitePaperComponent";

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';



//Navigation
const NavContainer = styled.div`
position: absolute;
min-width: 175px;
//min-height: 300px;
text-align: center;
padding: 0px;
margin:0;
transform-origin: center, center;
transform: translate(-50%, 50%);
left: 50vw;
//background: yellow;

@media (min-width: 320px) {
//right: 25px;
bottom: 5vh;
}

@media (min-width: 768px) {
//right: 25px;
bottom: 5vh;
}

@media (min-width: 1024px) {
//right: 90px;
bottom: 5vh;
}

@media (min-width: 2560px) {
//right: 90px;
bottom: 5vh;
}
`

const NavBar = styled.div`
vertical-align: top;
text-align: right;
padding: 0px;
margin: 0px;
font-family: 'ethnocentric-light';
//background: blue;
`

const theme = createTheme({
  palette: {
    primary: {
      main: '#b94bff',
    }
  }
});

const darkTheme = createTheme({
  palette: {
      mode: 'dark',
    }
});

const Pages = () => {
  const [draw, setDraw] = React.useState(false)
  const toggleDrawer = () => {
    setDraw(!draw)
  }

return (
  <>
    <ThemeProvider theme={theme}>
      <NavContainer>
        <NavBar>


          <Stack 
            spacing={2} 
            direction="row"
            centered
          >

            
            <Button 
            onClick={()=>{
                fetch('litepaper.pdf', {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/pdf',
                  },
                })
                .then((response) => response.blob())
                .then((blob) => {
                  // Create blob link to download
                  const url = window.URL.createObjectURL(
                    new Blob([blob]),
                  );
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute(
                    'download',
                    `litepaper.pdf`,
                  );

                  // Append to html link element page
                  document.body.appendChild(link);

                  // Start download
                  link.click();

                  // Clean up and remove the link
                  link.parentNode.removeChild(link);
                });
              }
            }
            variant="contained"
            sx={{
              color: '#ffffff',
              fontFamily: 'ethnocentric',
              fontSize: 11,
              letterSpacing: 3,
              paddingBottom: 1,
            }}
            >
              LITEPAPER
            </ Button>
          
            <Button
            Component="Link" 
            onClick={() => {
                window.open("https://twitter.com/noobguppy")
            }}
            variant="contained"
            sx={{
              color: '#ffffff',
              fontFamily: 'ethnocentric',
              fontSize: 11,
              letterSpacing: 3,
              paddingBottom: 1,
            }}
           
            >
              TWITTER
            </ Button>
            </Stack>
        </NavBar>
      </NavContainer>
    </ThemeProvider>
      <ThemeProvider theme={darkTheme}>
      <Drawer
        anchor="left"
        open={draw}
        onClose={toggleDrawer}
      >
        <LitePaperComponent />
      </Drawer>
      </ThemeProvider>
  </>
)

}

class NavAndPagesComponent extends React.Component {



    render() {
      return (
        <>
                <Pages/>
        </>
      )
    }
  }
  export default NavAndPagesComponent;