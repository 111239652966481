import React, { useState } from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List, { listClasses } from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import ConstructionIcon from '@mui/icons-material/Construction';

import litePaperData from "./litePaperData";


  const LitePaperListItem = () => {
    const docsTheme = createTheme({
      palette: {
        mode: "dark",
      },

      typography: {
        fontSize: 11,
        fontFamily: "ethnocentric",
        color: "#000",
      },

      text: {
        primary: "#000",
      },
    });

    const [open, setOpen] = useState(false);

    const handleClick = () => {
      setOpen(!open);
    };

    return (
      <>
        <ThemeProvider theme={docsTheme}>
          <CssBaseline />

          <List
            component="li"
            sx={{
              width: "100%",
              maxWidth: 800,
              letterSpacing: 3,
            }}
            aria-labelledby="LitePaper"
            subheader={
              <ListSubheader component="div">NGD LitePaper</ListSubheader>
            }
          >
                <ListItemButton divider selected>
                  <ListItemIcon><ConstructionIcon /></ListItemIcon>
                  <ListItemText primary="LITEPAPER DETAILS COMING SOON"
                  sx={{
                    color:'#cc99ff',
                  }} 
                  />
                </ListItemButton>


            {litePaperData.map((chapter) => {
              return (
                <ListItemButton key={chapter.titleNo} onClick={handleClick} divider>
                  <ListItemIcon>{chapter.titleNo}</ListItemIcon>
                  <ListItemText primary={chapter.titleName} />
                  {/* {open ? <RemoveIcon /> : <AddIcon />} */}
                </ListItemButton>
              );
            })}




          </List>

        </ThemeProvider>
      </>
    );
  };

  

class LitePaperComponent extends React.Component {
          render() {
            return (
              <>
                <LitePaperListItem />
              </>
            );
          }
        }
export default LitePaperComponent;
























