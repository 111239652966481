import React from "react";
import BgImage1 from "./assets/black-room-1.jpg";
import BgImage2 from "./assets/grey-room-1.jpg";
import BgImage3 from "./assets/white-room-4.jpg";
import BgImage4 from "./assets/white-room-5.jpg";
import BgImage5 from "./assets/white-room-6.jpg";
import BgImage6 from "./assets/white-room-7.jpg";
import styled from "styled-components";
import StaticGuppy from "./StaticGuppy";
import GuppyComponentViewer from './GuppyComponentViewer';
import Lottie from 'react-lottie-player'
import AnimationJson from "./assets/animation.json";

const RANDOM_BG = false;


const bgImgs = [BgImage1, BgImage2, BgImage3, BgImage4, BgImage5, BgImage6];
let bgImg = bgImgs[1];

if(RANDOM_BG){
  bgImg = bgImgs[Math.floor(Math.random() * bgImgs.length)]
}


// Canvas for 3d objects
const CanvasContainer = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  min-height: 800px;
  margin: 0;
  padding: 0;
  //background: black;
  background: url(${bgImg}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

function CanvasComponent(props){
  return (
    <>
      <CanvasContainer>
        <StaticGuppy
          guppyChoice={props.dialerChoice}
        />
        <GuppyComponentViewer
          dialerChoice={props.dialerChoice}
          dialerCallback={props.dialerCallback}
        />
      </CanvasContainer>
    </>
  );
}
export default CanvasComponent;
