import React from 'react'
import LogoImage from '../media/logo-2-white.png'
import styled from 'styled-components'
import Subscriber from './Subscriber'

const HeaderContainer = styled.div`
position: absolute;
//background-color: green;

@media (min-width: 320px) {
    width: 400px;
    top: 32px;
    transform: translate(-50%, 0%);
    left: 50vw;
    text-align: center;
  }
  @media (min-width: 768px) {
    width:500px;
    top: 32px;
    transform: translate(-50%, 0%);
    left: 50vw;
    text-align: center;
  }
  @media (min-width: 1024px) {
    width: 800px;
    top: 75px;
    transform: translate(-50%, 0%);
    left: 50vw;
    text-align: center;
  }
  @media (min-width: 1800px) {
    width: 800px;
    top: 75px;
    left: 50vw;
    //text-align: center;
    transform: translate(-50%, 0%);
    left: 50vw;
    text-align: center;
  }
  @media (min-width: 2560px) {
    width: 1000px;
    top: 75px;
    left: 50vw;
    //text-align: center;
    transform: translate(-50%, 0%);
    left: 50vw;
    text-align: center;
  }
`


const LogoComponent = styled.img`


@media (min-width: 320px) {
  width: 35vw;
}
@media (min-width: 768px) {
  width: 30vw;
}
@media (min-width: 1024px) {
  width: 25vw;
}
@media (min-width: 1800px) {
  width: 20vw;
}
@media (min-width: 2560px) {
  width: 15vw;
}
`

const MainTitle = styled.div`
padding: 5px 0px 0px 0px;
margin: 0px;
font-family: 'ethnocentric-light';
line-height: 3;
color: white;
//background: yellow;

@media (min-width: 320px) {
  font-size: 1.4ch;
  display: block;
  letter-spacing: 1.4ch;
}
@media (min-width: 768px) {
  font-size: 1.4ch;
  display: block;
  letter-spacing: 2ch;
}
@media (min-width: 1024px) {
  font-size: 1.4ch;
  display: block;
  letter-spacing: 2ch;
}
@media (min-width: 1800px) {
    font-size: 2ch;
    display: block;
    letter-spacing: 1.8ch;
}
@media (min-width: 2560px) {
  font-size: 2.2ch;
  display: block;
  letter-spacing: 2ch;
}
`


const SubTitle = styled.div`
position: absolute;
transform: translate(-50%, 0%);
left: 50vw;
font-family: 'ethnocentric-light';
letter-spacing: .8ch;
line-height: 3;
text-align: center;
//background: yellow;

@media (min-width: 320px) {
  font-size: 1.4ch;
  width: 280px;
  top: 72vh;
}
@media (min-width: 768px) {
  font-size: 1.6ch;
  width: 700px;
  top: 78vh;
}
@media (min-width: 1024px) {
  font-size: 1.8ch;
  width: 800px;
  top: 78vh;
}
@media (min-width: 1800px) {
    font-size: 2.2ch;
    width: 900px;
    top: 78vh;
}
@media (min-width: 2560px) {
  font-size: 2.4ch;
  width: 1000px;
  top: 78vh;
}
`




class HeaderComponent extends React.Component {

    render() {
      return (
        <>
            <HeaderContainer>
            <LogoComponent src={LogoImage} alt="Noob-Guppy-Dao" />
            <MainTitle>NOOB GUPPY DAO</ MainTitle>
            <Subscriber />
            </HeaderContainer>
            <SubTitle><font color="b94bff">DECENTRALIZED</font> <font color="ffffff">VENTURE CAPITAL</font></ SubTitle>
        </>
      )
    }
  }
  export default HeaderComponent;